import React, {useEffect} from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function About() {

  useEffect(() => {
    window.location.reload();
  }, []);
  return (
    <>
     <div><Header /> </div> 

        <div>

        <section>
        <div class="row">
          <div className="col-6">
            <div className="aboutng"  style={{background:'#fff'}}> 
              <h2>About RKP & Associates</h2>
              <p className="aboutp">
              At RKP, our journey is woven with the threads of passion,
                expertise, and longstanding commitment to our clients.
                Founded on the belief that legal representation should be
                more than just a service 'it should be a partnership'
                , we have
                dedicated ourselves to providing unparalleled counsel across
                a myriad of legal realms. </p>

                <p className="aboutp">
                Since inception, our firm emerged from a vision to redefine
                the traditional legal experience. With a team of seasoned
                attorneys at the helm, each possessing a unique set of skills
                and experiences, we embarked on a mission to offer
                personalised, client-centric legal solutions that transcend the
                boundaries of convention
                            </p>
             
            </div>
            </div>
          <div className="col-6">

        <div className="homecarousal">
        <img src="assets/img/3.jpeg" />
     
     </div>
     </div>
     </div>
     </section>

     
      


          {/*===== ABOUT STARTS =======*/}
          <div className="about7-section-area homess" style={{backgroundColor:'rgb(250, 250, 250)', padding:'100px 0px'}}>
          <div className="container">
              <div className="row">
              
                <div className="col-lg-8 bgrey">
                  <div className="about7-header-area align-items-center sp1 flex bgblue pdb-50">
                    <div>
                      <h2> <span className="defence">Our Story </span></h2>
                      <p>At RKP, our journey is woven with the threads of passion, expertise, and longstanding commitment to our clients. Founded on the belief that legal representation should be more than just a service 'it should be a partnership', we have dedicated ourselves to providing unparalleled counsel across a myriad of legal realms.
</p>
                      <p style={{marginTop:15}}>Since inception, our firm emerged from a vision to redefine the traditional legal experience. With a team of seasoned attorneys at the helm, each possessing a unique set of skills and experiences, we embarked on a mission to offer personalised, client-centric legal solutions that transcend the boundaries of convention.

</p>
                      <p style={{marginTop:15}}>Our story is one of evolution, driven by our deep-rooted desire to adapt and excel in an ever-changing legal landscape. From our humble beginnings to our current stature as a respected boutique law firm, we have remained steadfast in our pursuit of excellence.

</p>

<p style={{marginTop:15}}>What sets us apart is not only our legal acumen but also our unwavering dedication to understanding the unique needs and aspirations of each client we serve. 
We recognise that every case is different, every challenge is distinct, and every client goal deserves personalised attention. It is our ethos that forms the cornerstone of our practice into a commitment to delivering tailored solutions that prioritise the success and well-being of our clients above all else.

</p>

                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                    {/* <img src="assets/img/3.jpeg" alt="" /> */}
                </div>


              </div>

           
          </div>

          <div className="container">
           
              <div className="row">
              <div className="col-lg-6">
                  
<img src="assets/img/images/bsg.png" alt="" className="immg" /> 
              </div>
              <div className="col-lg-6 bgrey">
                <div className="about7-header-area align-items-center sp1 flex bgblue pdb-50" style={{marginTop:'-50px', paddingTop:40, background: '#f2f2f2'}}>
                  <div>
                  
<p style={{marginTop:15}}>Our journey has been defined by the relationships we've built along the way. We are immensely proud of the trust that our clients have placed in us, and we remain humbled by the opportunity to make a meaningful impact in their lives and businesses. From startups to established corporations, entrepreneurs to innovators, we stand by our clients as trusted advisors, advocates, and partners, guiding them through legal complexities with clarity, confidence, and compassion.

</p>

<p style={{marginTop:15}}>As we look toward the future, we do so with optimism and determination, fueled by our dedication to serving our clients with excellence, integrity, and innovation. Our story is a narrative of resilience, growth, and holy commitment to the pursuit of justice and success.

</p>
<p style={{marginTop:15}}>We look forward to serve our clients today and for years to come.
</p>


                  </div>
                </div>
              </div>
              


            </div>
          </div>
          </div>
          {/*===== ABOUT ENDS =======*/}

   


       
         
        </div>

        <div><Footer /> </div>
    </>
  );
}

export default About;
