import React, {useEffect} from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { BrowserRouter, Route, Link, Routes, Navigate, Switch, HashRouter} from 'react-router-dom';

function Team() {
    
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
     <div><Header /> </div> 

        <div style={{backgroundColor:'#F2F7FB', paddingTop:70}}>
        <>
  {/*===== TEAM STARTS =======*/}
    {/*===== WELCOME STARTS =======*/}
    <div
    className="welcome-inner-section-area"
    style={{
      // backgroundImage: "url(assets/img/bacground/inner-bg.png)",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      paddingTop:50,
      backgroundColor:'#023e8a'
    }}
  >
   
    <div className="container">
      <div className="row">
        <div className="col-lg-3 m-auto">
          <div className="welcome-inner-header text-center">
            <h1>Our Team</h1>
           <br/>  <br/>
            {/* <img src="assets/img/elements/elementor20.png" alt="" /> */}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="team2-section-area team-inner sp3">
    <div className="container-fluid">
      <div className="row" style={{marginBottom:30}}>
      <div className="col-lg-3 col-md-3">
          <div className="team2-parent-boxarea">
            <div className="team2-boxarea">
              <div className="team2images">
                <img src="assets/img/team/ran.jpeg" alt="" />
              </div>
              {/* <div className="team2-social-links">
              <div className="team2-parent-boxarea">
            <div className="team2-boxarea">
            <p style={{marginTop:15}}>
            Ranjan is the founder of the firm and has been instrumental in the growth of the firm over a
period of time. 
            </p>
          
            </div>
           
          </div>
              </div> */}
            </div>
            <div className="team2-textarea">
              <div className="teamsname">
                <Link to="/ranjan">Ranjan Kumar Pandey</Link>
                <p>Founding Partner</p>
                <p style={{marginTop:10}}>
            Ranjan is the founder of the firm and has been instrumental in the growth of the firm over a
period of time. </p>
              <Link to="/ranjan" className="read-more">Read More  <i class="fa-thin fa-arrow-right"></i></Link>
              </div>
              {/* <div className="shareicon">
                <a href="#">
                  <i className="fa-light fa-share-nodes" />
                </a>
              </div> */}
            </div>
          </div>
        </div>


        <div className="col-lg-3 col-md-3">
          <div className="team2-parent-boxarea">
            <div className="team2-boxarea">
              <div className="team2images">
                <img src="assets/img/team/kul.jpeg" alt="" />
              </div>
              {/* <div className="team2-social-links">
              <div className="team2-parent-boxarea">
            <div className="team2-boxarea">
            <p style={{marginTop:15}}>
            Ranjan is the founder of the firm and has been instrumental in the growth of the firm over a
period of time. 
            </p>
          
            </div>
           
          </div>
              </div> */}
            </div>
            <div className="team2-textarea">
              <div className="teamsname">
                <Link to="/kuldeep">K. K. Bhat</Link>
                <p>Partner</p>
                <p style={{marginTop:10}}>Kuldip Kumar has immense exposure in the field of insurance functioning and laws.  </p>
                <Link to="/kuldeep" className="read-more">Read More  <i class="fa-thin fa-arrow-right"></i></Link>
              </div>
              {/* <div className="shareicon">
                <a href="#">
                  <i className="fa-light fa-share-nodes" />
                </a>
              </div> */}
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-3">
          <div className="team2-parent-boxarea">
            <div className="team2-boxarea">
              <div className="team2images">
                <img src="assets/img/team/rit.jpeg" alt="" />
              </div>
              {/* <div className="team2-social-links">
              <div className="team2-parent-boxarea">
            <div className="team2-boxarea">
            <p style={{marginTop:15}}>
            Ranjan is the founder of the firm and has been instrumental in the growth of the firm over a
period of time. 
            </p>
          
            </div>
           
          </div>
              </div> */}
            </div>
            <div className="team2-textarea">
              <div className="teamsname">
              <Link to="/ritwik">Ritwik Sahay</Link>
                <p> Partner</p>
                <p style={{marginTop:10}}>Ritwik advises investors in their M&A deals. He has also been assisting the start-ups in their fund raise exercise.</p>
                <Link to="/ritwik" className="read-more">Read More  <i class="fa-thin fa-arrow-right"></i></Link>
              </div>
              {/* <div className="shareicon">
                <a href="#">
                  <i className="fa-light fa-share-nodes" />
                </a>
              </div> */}
            </div>
          </div>
        </div>

        
        <div className="col-lg-3 col-md-3">
          <div className="team2-parent-boxarea">
            <div className="team2-boxarea">
              <div className="team2images">
                <img src="assets/img/team/sandeep.png" alt="" />
              </div>
              {/* <div className="team2-social-links">
              <div className="team2-parent-boxarea">
            <div className="team2-boxarea">
            <p style={{marginTop:15}}>
            Ranjan is the founder of the firm and has been instrumental in the growth of the firm over a
period of time. 
            </p>
          
            </div>
           
          </div>
              </div> */}
            </div>
            <div className="team2-textarea" style={{
    bottom: '-111px'}}>
              <div className="teamsname">
              <Link to="/sandeep" >Sandeep Bisht</Link>
                <p> Partner</p>
                <p style={{marginTop:10}}>Sandeep has expertise in handling commercial disputes. He has immense exposure...</p>
                <Link to="/sandeep" className="read-more">Read More  <i class="fa-thin fa-arrow-right"></i></Link>
              </div>
              {/* <div className="shareicon">
                <a href="#">
                  <i className="fa-light fa-share-nodes" />
                </a>
              </div> */}
            </div>
          </div>
        </div>
    
     </div>
     <div className="row" style={{marginBottom:30}}>
     

     
</div>



    </div>
  </div>
  {/*===== TEAM ENDS =======*/}
</>



       
         
        </div>

        <div><Footer /> </div>
    </>
  );
}

export default Team;
