import React, {useEffect} from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { BrowserRouter, Route, Link, Routes, Navigate, Switch, HashRouter} from 'react-router-dom';

function Kuldeep() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
     <div><Header /> </div> 

        <div style={{backgroundColor:'#fff', paddingTop:70}}>
        <>
  {/*===== TEAM STARTS =======*/}
    {/*===== WELCOME STARTS =======*/}
    <div
    className="welcome-inner-section-area"
    style={{
      // backgroundImage: "url(assets/img/bacground/inner-bg.png)",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      paddingTop:50,
      background: '#F2F7FB'
    }}
  >
   
    <div className="container">
      <div className="row">

     


        <div className="col-lg-3 m-auto">
        <img src="assets/img/team/kul.png" alt="" />
        <h3 className="headd">K. K. Bhat</h3>
            <p className="para" style={{textAlign:'center', marginBottom:50}}>Partner</p>

        </div>
         <div className="col-lg-9 m-auto">
         <div className="team2-parent-boxarea">
         <div className="team2-boxarea" style={{paddingLeft:40}}>
         <p style={{marginTop:15}} className="para">Kuldip Kumar has immense exposure in the field of insurance functioning and laws. He has been instrumental in providing valuable inputs for development of products of general insurance, and also in matters related to the Insurance Regulator.

</p>
         <p style={{marginTop:15}} className="para">He is currently handling the litigation relating to many general insurance companies in public and private sector. He is also a visiting faculty to officers’ training institute of a general insurance company.

He has also been handling corporate-documentation work in Insurance sector.</p>
         </div>
        
       </div>

            </div>
      </div>
    </div>
  </div>


  {/*===== TEAM ENDS =======*/}
</>



       
         
        </div>

        <div><Footer /> </div>
    </>
  );
}

export default Kuldeep;
