import React, { useState } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function Contact() {
  function isValidEmail(email) {
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const [Pnumber, setPnumber] = useState("");
  const [Email, setEmail] = useState("");
  const [msg, setmsg] = useState("");
  const [UserName, setUserName] = useState("");
  const [msgsubject, setmsgsubject] = useState("");

  async function submitCustmDataEmailNew() {
    if (
      UserName === "" ||
      Pnumber === "" ||
      Email === "" ||
      msg === "" ||
      msgsubject === ""
    ) {
      alert("Please fill out all required fields.");
      return;
    } else if (!isValidEmail(Email)) {
      alert("Please enter a valid email address.");
      return;
    } else {
      const data = {
        query: msg,
        name: UserName,
        email: Email,
        mobile: Pnumber,
        subject: msgsubject,
        domain: "rkpandassociates.in",
      };

      const headers = {
        "Content-Type": "application/json",
        "authorization": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NzI5ZDU1ODFmZGU4NTM3YWI3OGQwMWMiLCJzZXNzaW9uIjoiNjcyOWQ1NTgxZmRlODUzN2FiNzhkMDFlIiwibmFtZSI6InJrcGFuZGFzc29jaWF0ZXMiLCJlbWFpbCI6ImluZm9AcmtwYW5kYXNzb2NpYXRlcy5pbiIsInVzZXJUeXBlIjoidXNlciIsInVzZXJJZCI6IjY3MjlkNTU4MWZkZTg1MzdhYjc4ZDAxYyIsImlhdCI6MTczMDc5NDg0MCwiZXhwIjoxODg4NTgyODQwfQ.fYmTpN-rCyA0ccJDChImpMs0ngvK8tAuR2r1ot9gv9Ksqk2y_pzc-gGOs4_H8f4yPa9ROZ3k-gWFuekVH5xe1EQGg5oS2UIN4cxXvQzbXmqjLNI_xzqcCfnjTWJ9rQdHD1wzlL0nCxbkAVVef0yENYyia7T-6660NDghvRgj5SJwocTZfLd2bh6mfRyK89X6koqdfdhe_XEbzKITc_2pQQrhfjQB3M_N1fCXJbZlage0GeENYRW5xJsnhKTvNRmq_R8sy1-OFQPsbYq5BLVPA86hw415i2VlomtYfelxMeLXYSa-kp378alcugOfxbsIpreiTw7ClWGtydsATh_oaQ",
        "Accept": "application/json",
        "Accept-Language": "en",
      };
      try {
        debugger;
        const response = await fetch(
          "https://projects.erpthemes.com/api/dynamic/addRecordsDynamic?tempID=25&tempName=rkp_contact_us",
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
          }
        );
        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`
          );
        }
        const result = await response.json();
        if (result.resp.status) {
          alert(result.resp.message);
          window.scrollTo(0, 0);
          window.location.reload();
        } else {
          console.log(result.error);
          alert("Please try again later.");
        }
      } catch (error) {
        console.error("Error during fetch:", error.message);
      }
    }
  }
  return (
    <>
      <div>
        <Header />{" "}
      </div>

      <div className="contacts">
        {/*===== CONTACT STARTS =======*/}
        <div
          className="contact7-section-area sp1"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 m-auto">
                <div className="contact7-header text-center">
                  <h2>Connect Our Experts</h2>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="contact-submit-area">
                  <h3>Send us a Message</h3>
                  {/* <p>As a fellow small business owner, we know the fulfillment that an a best to comes from running &amp; own business contact our service to Finance.</p> */}
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="contact-info">
                        <input
                          type="text"
                          placeholder="Full Name*"
                          value={UserName}
                          onChange={(e) => {
                            setUserName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="contact-info">
                        <input
                          type="text"
                          placeholder="Email*"
                          value={Email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="contact-info">
                        <input
                          type="text"
                          placeholder="Phone*"
                          value={Pnumber}
                          maxLength={10}
                          onChange={(e) => {
                            setPnumber(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="contact-info">
                        <textarea
                          placeholder="Message*"
                          cols={30}
                          rows={10}
                          defaultValue={""}
                          value={msg}
                          onChange={(e) => {
                            setmsg(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="contact-info subject">
                        <input
                          type="text"
                          placeholder="Subject*"
                          value={msgsubject}
                          onChange={(e) => {
                            setmsgsubject(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="contact-info ">
                        <button
                          type="submit"
                          onClick={() => submitCustmDataEmailNew()}
                        >
                          Submit <i className="fa-regular fa-arrow-right" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-main-boxarea">
                  <div className="div">
                    <div className="contact-box-area">
                      <div className="contact-icon">
                        <img src="assets/img/icons/clock1.svg" alt="" />
                      </div>
                      <div className="contact-content">
                        <h4>Contact us</h4>
                        <a href="#">
                          RKP & ASSOCIATES 301, Ashadeep, 9, Hailey Road, New
                          Delhi
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="space20" />
                  <div className="div">
                    <div className="contact-box-area">
                      <div className="contact-icon">
                        <img src="assets/img/icons/phone2.svg" alt="" />
                      </div>
                      <div className="contact-content">
                        <h4>Call or text</h4>
                        <a href="tel:+91 11 43540664">+91 11 43540664 </a>/
                        <a href="tel:43540665">43540665</a>
                      </div>
                    </div>
                  </div>
                  <div className="space20" />
                  <div className="div">
                    <div className="contact-box-area">
                      <div className="contact-icon">
                        <img src="assets/img/icons/email2.svg" alt="" />
                      </div>
                      <div className="contact-content">
                        <h4>Email us today</h4>
                        <a href="mailto:info@rkpandassociates.in">
                          info@rkpandassociates.in
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*===== CONTACT ENDS =======*/}
      </div>

      <div>
        <Footer />{" "}
      </div>
    </>
  );
}

export default Contact;
