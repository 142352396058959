import React, {useEffect} from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { BrowserRouter, Route, Link, Routes, Navigate, Switch, HashRouter} from 'react-router-dom';

function Sandeep() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
     <div><Header /> </div> 

        <div style={{backgroundColor:'#fff', paddingTop:70}}>
        <>
  {/*===== TEAM STARTS =======*/}
    {/*===== WELCOME STARTS =======*/}
    <div
    className="welcome-inner-section-area"
    style={{
      // backgroundImage: "url(assets/img/bacground/inner-bg.png)",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      paddingTop:50,
      background: '#F2F7FB'
    }}
  >
   
    <div className="container">

    <div className="row">
        <div className="col-lg-3 m-auto">
            <img src="assets/img/team/sandeep.png" alt="" />
            <h3 className="headd">Sandeep Bisht</h3>
            <p className="para" style={{textAlign:'center', marginBottom:10}}> Partner</p>
            <Link onClick={() => window.open('http://linkedin.com/in/sandeep-bisht-328a78206', '_blank')}  ><img src="assets/img/linkedin.png" className="linked linkedd" /></Link> 
        </div>
        <div className="col-lg-9 m-auto">
        <div className="team2-parent-boxarea">
         <div className="team2-boxarea" style={{paddingLeft:40}}>
         <p style={{marginTop:15}} className="para">Sandeep has expertise in handling commercial disputes. He has immense exposure and experience in representing corporate houses in merger & acquisition proceedings before NCLT. He has also been regularly advising clients on oppression and mismanagement issues and related proceedings before NCLT and NCLAT.

</p>
         <p style={{marginTop:15}} className="para">He is also a registered Patent Agent and is one of the most known faces in the Trademarks Office, Patents Office, Copyright Office and Intellectual Property Appellate Board. He has been advising companies on flow of Intellectual Property Rights in Merger & Acquisition transactions.

</p>
         <p style={{marginTop:15}} className="para">He has been associated with FICCI for their various workshops throughout the country on 'Clean Technology & Patent Law'.</p>
         </div>
        
       </div>

            </div>

      </div>


    </div>
  </div>

  
  {/*===== TEAM ENDS =======*/}
</>



       
         
        </div>

        <div><Footer /> </div>
    </>
  );
}

export default Sandeep;
