import React, { useState, useEffect } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { Carousel } from "react-responsive-carousel";
import Marquee from "react-fast-marquee";

function Home() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  function onDismiss() {
    setOpen(false);
  }
  const [Phonenumber, setPhonenumber] = useState("");
  const [Email, setEmail] = useState("");
  const [msg, setmsg] = useState("");
  const [UserName, setUserName] = useState("");
  const [subject, setsubject] = useState("");

  function isValidEmail(email) {
    // Basic email validation regex
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  useEffect(() => {}, [0]);
  async function submitCustmDataEmailNew() {
    if (
      UserName === "" ||
      Phonenumber === "" ||
      Email === "" ||
      msg === "" ||
      subject === ""
    ) {
      alert("Please fill out all required fields.");
      return;
    } else if (!isValidEmail(Email)) {
      alert("Please enter a valid email address.");
      return;
    } else {
      const data = {
        query: msg,
        name: UserName,
        email: Email,
        mobile: Phonenumber,
        subject: subject,
        domain: "rkpandassociates.in",
      };

      const headers = {
        "Content-Type": "application/json",
        "authorization": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NzI5ZDU1ODFmZGU4NTM3YWI3OGQwMWMiLCJzZXNzaW9uIjoiNjcyOWQ1NTgxZmRlODUzN2FiNzhkMDFlIiwibmFtZSI6InJrcGFuZGFzc29jaWF0ZXMiLCJlbWFpbCI6ImluZm9AcmtwYW5kYXNzb2NpYXRlcy5pbiIsInVzZXJUeXBlIjoidXNlciIsInVzZXJJZCI6IjY3MjlkNTU4MWZkZTg1MzdhYjc4ZDAxYyIsImlhdCI6MTczMDc5NDg0MCwiZXhwIjoxODg4NTgyODQwfQ.fYmTpN-rCyA0ccJDChImpMs0ngvK8tAuR2r1ot9gv9Ksqk2y_pzc-gGOs4_H8f4yPa9ROZ3k-gWFuekVH5xe1EQGg5oS2UIN4cxXvQzbXmqjLNI_xzqcCfnjTWJ9rQdHD1wzlL0nCxbkAVVef0yENYyia7T-6660NDghvRgj5SJwocTZfLd2bh6mfRyK89X6koqdfdhe_XEbzKITc_2pQQrhfjQB3M_N1fCXJbZlage0GeENYRW5xJsnhKTvNRmq_R8sy1-OFQPsbYq5BLVPA86hw415i2VlomtYfelxMeLXYSa-kp378alcugOfxbsIpreiTw7ClWGtydsATh_oaQ",
        "Accept": "application/json",
        "Accept-Language": "en",
      };
      try {
        debugger;
        const response = await fetch(
          "https://projects.erpthemes.com/api/dynamic/addRecordsDynamic?tempID=25&tempName=rkp_contact_us",
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
          }
        );
        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`
          );
        }
        const result = await response.json();
        if (result.resp.status) {
          alert(result.resp.message);
          window.scrollTo(0, 0);
          window.location.reload();
        } else {
          console.log(result.error);
          alert("Please try again later.");
        }
      } catch (error) {
        console.error("Error during fetch:", error.message);
      }
    }
  }
  async function submiCustmDataEmailNew() {
    if (
      UserName === "" ||
      Phonenumber === "" ||
      Email === "" ||
      msg === "" ||
      subject === ""
    ) {
      alert("Please fill out all required fields.");
      return;
    }
    // Validate email format (basic check)
    else if (!isValidEmail(Email)) {
      alert("Please enter a valid email address.");
      return;
    } else {
      const data = {
        ClientId: "64ff8e16-df25-417e-8d7f-730a012234ea",
        Query: msg,
        Name: UserName,
        Email: Email,
        Mobile: Phonenumber,
        Subject: subject,
        Domain: "rkpandassociates.in",
      };

      const headers = {
        "Content-Type": "application/json",
        clientid: "64ff8e16-df25-417e-8d7f-730a012234ea",
        "page-name": "Contact_Us",
        //"SendToEmail": "Chandrakant111420@gmail.com",
        EmailSubject: "Contact Form Submission",
      };
      try {
        debugger;
        const response = await fetch(
          "https://crmleads.erpthemes.com/save-result",
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
          }
        );
        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`
          );
        }
        const result = await response.json();
        if (result.data) {
          alert(
            "Thank you for reaching out to us; we will get back to you shortly. Lead id: " +
              result.data[0].ID
          );
          window.scrollTo(0, 0);
          window.location.reload();
        } else {
          console.log(result.error);
          alert("Please try again later.");
        }
      } catch (error) {
        console.error("Error during fetch:", error.message);
      }
    }
  }
  return (
    <>
      <div>
        <Header />{" "}
      </div>

      <div>
        <section>
          <div class="row">
            <div className="col-12 col-lg-6">
              <div className="bgLayers">
                <h1>RKP & Associates</h1>
                <h5>Law firm with a solution-oriented approach.</h5>
                {/* <a className="header__bar hamburger_menu header__bar-icon header_bar5">
              <i class="fa-thin fa-arrow-right"></i>
                        </a> */}
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="homecarousal">
                <Carousel autoPlay={true} infiniteLoop={true} interval={4000}>
                  <div>
                    <img src="assets/img/imm/1.jpeg" />
                    {/* <p className="legend">Legend 1</p> */}
                  </div>
                  <div>
                    <img src="assets/img/imm/3.jpeg" />
                  </div>
                  <div>
                    <img src="assets/img/imm/2.jpeg" />
                  </div>
                  <div>
                    <img src="assets/img/imm/5.jpeg" />
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </section>

        {/*  
   <div className="about7-section-area home">
          
              <div className="row">
        
              <div className="col-lg-4">
                  <div className="about7-header-area sp1 bgblue">
                    <h6>Firm News</h6>
              
               <p style={{marginTop:15}}>Advised upGrad, on its fund raises  from Series 1 – Series 4 aggregating to approx. USD 395 million from Temasek, World Bank’s International Finance Corporation, ETS Global, IIFL and  Lakshmi Mittal of ArcelorMittal.
</p>
<a className="">
              <i class="fa-thin fa-arrow-right"></i>
                        </a>
                  </div>
                </div>
                
                <div className="col-lg-4">
                  <div className="about7-header-area sp1">
                  <h6>Firm News</h6>
               <p style={{marginTop:15}}>Advised Lenskart and certain existing shareholders on the primary and secondary investment by ADIA aggregating to approx. USD. 500 million
</p>
<a className="">
              <i class="fa-thin fa-arrow-right"></i>
                        </a>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="about7-header-area sp1 bgblue">
                  <h6>Firm News</h6>
               <p style={{marginTop:15}}>Rajaram Legal was ranked as a Band 3 law firm for Private Equity in the Chambers and Partners. Archana Rajaram was ranked as a Band 2 lawyer.
</p>
<a className="">
              <i class="fa-thin fa-arrow-right"></i>
                        </a>
                  </div>
                </div>
                
            
            </div>
          </div>
    */}
        {/* <section>
        <div class="row">   <div className="col-6">

<div className="homecarousal">
<img src="assets/img/3.jpeg" />

</div>
</div>
          <div className="col-6">
            <div className="aboutng"  style={{background:'#fff'}}> 
              <h2>About RKP & Associates</h2>
              <p className="aboutp">
              At RKP, our journey is woven with the threads of passion,
                expertise, and longstanding commitment to our clients.
                Founded on the belief that legal representation should be
                more than just a service 'it should be a partnership'
                , we have
                dedicated ourselves to providing unparalleled counsel across
                a myriad of legal realms. </p>

                <p className="aboutp">
                Since inception, our firm emerged from a vision to redefine
                the traditional legal experience. With a team of seasoned
                attorneys at the helm, each possessing a unique set of skills
                and experiences, we embarked on a mission to offer
                personalised, client-centric legal solutions that transcend the
                boundaries of convention
                            </p>
             
            </div>
            </div>
       
     </div>
     </section>

      */}

        {/*===== ABOUT STARTS =======*/}
        <div
          className="about7-section-area homess"
          style={{
            backgroundColor: "rgb(250, 250, 250)",
            padding: "100px 0px 0px 0px",
          }}
        >
          <div className="container">
            <div className="row rws">
              <div className="col-lg-6 bgrey">
                <div className="about7-header-area align-items-center sp1 flex bgblue pdb-50">
                  <div>
                    <h2>
                      {" "}
                      <span className="defence">Our Story </span>
                    </h2>
                    <p>
                      At RKP, our journey is woven with the threads of passion,
                      expertise, and longstanding commitment to our clients.
                      Founded on the belief that legal representation should be
                      more than just a service 'it should be a partnership', we
                      have dedicated ourselves to providing unparalleled counsel
                      across a myriad of legal realms.
                    </p>
                    <p style={{ marginTop: 15 }}>
                      Since inception, our firm emerged from a vision to
                      redefine the traditional legal experience. With a team of
                      seasoned attorneys at the helm, each possessing a unique
                      set of skills and experiences, we embarked on a mission to
                      offer personalised, client-centric legal solutions that
                      transcend the boundaries of convention.
                    </p>
                    <p style={{ marginTop: 15 }}>
                      Our story is one of evolution, driven by our deep-rooted
                      desire to adapt and excel in an ever-changing legal
                      landscape. From our humble beginnings to our current
                      stature as a respected boutique law firm, we have remained
                      steadfast in our pursuit of excellence.
                    </p>

                    <p style={{ marginTop: 15 }}>
                      What sets us apart is not only our legal acumen but also
                      our unwavering dedication to understanding the unique
                      needs and aspirations of each client we serve. We
                      recognise that every case is different, every challenge is
                      distinct, and every client goal deserves personalised
                      attention. It is our ethos that forms the cornerstone of
                      our practice into a commitment to delivering tailored
                      solutions that prioritise the success and well-being of
                      our clients above all else.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <img src="assets/img/imm/11.jpeg" alt="" />
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row rws">
              <div className="col-lg-6">
                <img src="assets/img/imm/10.jpeg" alt="" className="immg" />
              </div>
              <div className="col-lg-6 bgrey">
                <div
                  className="about7-header-area align-items-center sp1 flex bgblue pdb-50"
                  style={{
                    marginTop: "-50px",
                    paddingTop: 40,
                    background: "#f2f2f2",
                  }}
                >
                  <div>
                    <p style={{ marginTop: 15 }}>
                      Our journey has been defined by the relationships we've
                      built along the way. We are immensely proud of the trust
                      that our clients have placed in us, and we remain humbled
                      by the opportunity to make a meaningful impact in their
                      lives and businesses. From startups to established
                      corporations, entrepreneurs to innovators, we stand by our
                      clients as trusted advisors, advocates, and partners,
                      guiding them through legal complexities with clarity,
                      confidence, and compassion.
                    </p>

                    <p style={{ marginTop: 15 }}>
                      As we look toward the future, we do so with optimism and
                      determination, fueled by our dedication to serving our
                      clients with excellence, integrity, and innovation. Our
                      story is a narrative of resilience, growth, and holy
                      commitment to the pursuit of justice and success.
                    </p>
                    <p style={{ marginTop: 15 }}>
                      We look forward to serve our clients today and for years
                      to come.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*===== ABOUT ENDS =======*/}

        {/*===== ABOUT STARTS 
          <div className="about7-section-area homess">
              <div className="row">
              
                <div className="col-lg-6 bgrey">
                  <div className="about7-header-area align-items-center sp1 flex">
                    <div>
                      <h2> <span className="defence">Our Story </span></h2>
                      <p>At RKP, our journey is woven with the threads of passion, expertise, and longstanding commitment to our clients. Founded on the belief that legal representation should be more than just a service 'it should be a partnership', we have dedicated ourselves to providing unparalleled counsel across a myriad of legal realms.</p>
                      <p style={{marginTop:15}}>Since inception, our firm emerged from a vision to redefine the traditional legal experience. With a team of seasoned attorneys at the helm, each possessing a unique set of skills and experiences, we embarked on a mission to offer personalised, client-centric legal solutions that transcend the boundaries of convention.
</p>
                      <p style={{marginTop:15}}>Our story is one of evolution, driven by our deep-rooted desire to adapt and excel in an ever-changing legal landscape. From our humble beginnings to our current stature as a respected boutique law firm, we have remained steadfast in our pursuit of excellence.
</p>
                      <p style={{marginTop:15}}><a className="">
              <i class="fa-thin fa-arrow-right"></i>
                        </a></p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                    <img src="assets/img/3.jpeg" alt="" />
                </div>


              </div>
          </div>
         ABOUT ENDS =======*/}

        {/*===== ABOUT STARTS =======*/}
        <div className="about7-section-area homess">
          <div className="row rws">
            <div className="col-lg-6">
              <img src="assets/img/imm/7.jpeg" alt="" />
            </div>
            <div className="col-lg-6 bgrey bgs" style={{ marginLeft: -25 }}>
              <div className="about7-header-area align-items-center sp1 flex">
                <div>
                  <h2>
                    {" "}
                    <span className="defence">Why choose us?</span>
                  </h2>
                  <p>
                    We are a team of qualified honchos committed to client
                    satisfaction with utmost precision and care aimed at
                    providing quality work in time bound manner.
                  </p>

                  <p style={{ marginTop: 15 }}>
                    <b>Handholding and Nurturing</b>
                  </p>

                  <p style={{ marginTop: 15 }}>
                    We pride ourselves on our hands-on approach, guiding you
                    through every legal challenge with care and expertise,
                    ensuring you feel supported and empowered every step of the
                    way.
                  </p>

                  <p style={{ marginTop: 15 }}>
                    <b>Pragmatic Approach </b>
                  </p>

                  <p style={{ marginTop: 15 }}>
                    Our solutions are grounded in pragmatism, focusing on
                    practical, effective strategies tailored to your unique
                    needs and circumstances.
                  </p>

                  <p style={{ marginTop: 15 }}>
                    <b>Quick Turnaround Time</b>
                  </p>

                  <p style={{ marginTop: 15 }}>
                    Time is of the essence, and we understand the importance of
                    prompt action. With our streamlined processes and efficient
                    team, we strive to deliver swift resolutions and responses
                    to your legal matters.
                  </p>
                  {/* <p style={{marginTop:15}}><a className="">
              <i class="fa-thin fa-arrow-right"></i>
                        </a></p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*===== ABOUT ENDS =======*/}

        <div class="others6-section-area">
          <div class="container">
            <div class="row">
              <div className="col-12">
                <h2
                  class="aos-init aos-animate"
                  style={{ textAlign: "center", marginBottom: 60 }}
                >
                  Key Clients
                </h2>
              </div>
              <div class="col-12">
                <Marquee>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/1.png" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/2.png" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/3.png" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/4.png" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/5.png" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/6.png" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/7.png" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/8.png" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/10.png" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/11.png" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/12.png" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/13.png" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/14.png" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/15.png" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/16.jpg" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/17.jpeg" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/18.png" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/19.png" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/20.png" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/21.png" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/22.jpg" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/23.png" alt="" />
                  </div>
                  <div class="text-headlines__item">
                    <img src="assets/img/clients/24.png" alt="" />
                  </div>
                </Marquee>
                {/* <div class="text-headlines" >
                    <div class="text-headlines__item" ><img src="assets/img/clients/1.png" alt=""/></div>
                    <div class="text-headlines__item" ><img src="assets/img/clients/2.png" alt=""/></div>
                    <div class="text-headlines__item" ><img src="assets/img/clients/3.png" alt=""/></div>
                    <div class="text-headlines__item" ><img src="assets/img/clients/4.png" alt=""/></div>
                    <div class="text-headlines__item" ><img src="assets/img/clients/5.png" alt=""/></div>
                    <div class="text-headlines__item" ><img src="assets/img/clients/6.png" alt=""/></div>
                    <div class="text-headlines__item" ><img src="assets/img/clients/7.png" alt=""/></div>
                    <div class="text-headlines__item" ><img src="assets/img/clients/8.png" alt=""/></div>
                    <div class="text-headlines__item" ><img src="assets/img/clients/10.png" alt=""/></div>
                    <div class="text-headlines__item" ><img src="assets/img/clients/11.png" alt=""/></div>
                    <div class="text-headlines__item" ><img src="assets/img/clients/12.png" alt=""/></div>
                    <div class="text-headlines__item" ><img src="assets/img/clients/13.png" alt=""/></div>
                  

                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/*===== CONTACT STARTS =======*/}
        <div
          className="contact7-section-area sp1"
          style={{
            backgroundImage: "url(assets/img/images/contact-bg1.png)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 m-auto">
                <div className="contact7-header text-center">
                  <span>Contact Us</span>
                  {/* <h2>Connect Our Legal Experts
                   </h2> */}
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="contact-submit-area">
                  <h3>Send us a Message</h3>
                  {/* <p data-aos="fade-up" data-aos-duration={1000}>As a fellow small business owner, we know the fulfillment that an a best to comes from running &amp; own business contact our service to Finance.</p> */}
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="contact-info">
                        <input
                          type="text"
                          placeholder="Full Name*"
                          onChange={(e) => {
                            setUserName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="contact-info">
                        <input
                          type="email"
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          placeholder="Email*"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="contact-info">
                        <input
                          type="number"
                          onChange={(e) => {
                            setPhonenumber(e.target.value);
                          }}
                          placeholder="Phone*"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="contact-info">
                        <textarea
                          placeholder="Message*"
                          onChange={(e) => {
                            setmsg(e.target.value);
                          }}
                          cols={30}
                          rows={10}
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="contact-info subject">
                        <input
                          type="text"
                          placeholder="Subject*"
                          onChange={(e) => {
                            setsubject(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="contact-info ">
                        <button
                          onClick={() => submitCustmDataEmailNew()}
                          type="submit"
                        >
                          Submit <i className="fa-regular fa-arrow-right" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-main-boxarea">
                  <div className="div">
                    <div className="contact-box-area">
                      {/* <div className="contact-icon">
                          <img src="assets/img/icons/clock1.svg" alt="" />
                        </div> */}
                      <div className="contact-content">
                        <h4>Address</h4>
                        <a href="#">301, Ashadeep, 9, Hailey Road, New Delhi</a>
                      </div>
                    </div>
                  </div>
                  <div className="space20" />
                  <div className="div">
                    <div className="contact-box-area">
                      {/* <div className="contact-icon">
                          <img src="assets/img/icons/phone2.svg" alt="" />
                        </div> */}
                      <div className="contact-content">
                        <h4>Telephone</h4>
                        <a href="tel:+91 11 43540664">+91 11 43540664 </a>/
                        <a href="tel:43540665">43540665</a>
                      </div>
                    </div>
                  </div>
                  <div className="space20" />
                  <div className="div">
                    <div className="contact-box-area">
                      {/* <div className="contact-icon">
                          <img src="assets/img/icons/email2.svg" alt="" />
                        </div> */}
                      <div className="contact-content">
                        <h4>Email</h4>
                        <a href="mailto:info@rkpandassociates.in">
                          info@rkpandassociates.in
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*===== CONTACT ENDS =======*/}
        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.1120631455824!2d77.22474637457262!3d28.626403284360702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd32f1eeaaab%3A0xa6cb887f02b92808!2sRKP%20%26%20Associates!5e0!3m2!1sen!2sin!4v1719486493584!5m2!1sen!2sin"
            width="100%"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      <BottomSheet
        open={open}
        onDismiss={onDismiss}
        blocking={true}
        backdrop={false}
        className="dis"
        height={300}
      >
        <div className="flexx">
          <p>
            By browsing this website you agree that you are, of your own accord,
            seeking further information regarding our firm, RKP & Associates. No
            part of this website should be construed as an advertisement or
            solicitation of our professional services.
          </p>
          <button onClick={onDismiss}>I Agree</button>
        </div>
      </BottomSheet>

      <div>
        <Footer />{" "}
      </div>
    </>
  );
}

export default Home;
