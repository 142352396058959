import React from "react";
import { Link } from 'react-router-dom';


function Footer(){

    return(
        <div className="footer7-section-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="footer-all-section-area sp5">
                <div className="row">
                  {/* <div className="col-lg-5 col-md-6">
                    <div className="footer-last-section">
                      <div className="footer-imgage">
                        <img src="assets/img/logo/logo.png" alt="" />
                      </div>
                      <div className="footer-text-area">
                        <p>At RKP, our journey is woven with the threads of passion, expertise, and longstanding commitment to our clients. Founded on the belief that legal representation should be more than just a service 'it should be a partnership', we have dedicated ourselves to providing unparalleled counsel across a myriad of legal realms.

</p>
                    
                      </div>
                    </div>
                  </div> */}
                  <div className="col-lg-6 col-md-6">
                    <div className="about-links-area">
                      <h3>Useful Links</h3>
                      <ul>
                        <li><Link to="/">Home</Link></li>
                        {/* <li><Link to="/about">About Us</Link></li> */}
                        <li><Link to="/expertise">Expertise</Link></li>
                        
                        <li><Link to="/industry-exposure">Industry Exposure</Link> </li>
                        <li><Link to="/team">Team</Link></li>
                        <li><Link to="/news">Newsroom</Link></li>
                        <li><Link to="https://projects.erpthemes.com/" target="_blank">Admin Login </Link></li>
                        {/* <li><Link to="/contact">Contact Us</Link></li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="get-links-area">
                      <h3>Get In Touch</h3>
                      <ul>
                        <li>
                          {/* <img src="assets/img/icons/footer-email2.svg" alt="" />  */}
                        <a href="maito:info@rkpandassociates.in">info@rkpandassociates.in</a></li>
                        <li>
                          {/* <img src="assets/img/icons/footer-location1.svg" alt="" /> */}
                          
                          <a href="#">301, Ashadeep,
9, Hailey Road,
New Delhi</a></li>
                        <li>
                          {/* <img src="assets/img/icons/footer-phn.svg" alt="" /> */}
                          <a href="#">+91 11 43540664 / 43540665</a></li>
                      <li> <Link onClick={() => window.open('https://www.linkedin.com/company/rkpandassociates/about/?viewAsMember=true', '_blank')}  ><img src="assets/img/linkedin.png" className="linked" /></Link> 
                      </li>
                      </ul>

                      {/* <div className="social-list-area">
                          <ul>
                            <li><a href="#"><i className="fa-brands fa-facebook-f" /></a></li>
                            <li><a href="#"><i className="fa-brands fa-x-twitter" /></a></li>
                            <li><a href="#"><i className="fa-brands fa-linkedin" /></a></li>
                            <li><a href="#"><i className="fa-brands fa-instagram" /></a></li>
                          </ul>
                        </div> */}

                    </div>
                  </div>
              
                </div>
              </div>
              <div className="copyright-pera">
                
              <div className="footer-imgage">
                        <img src="assets/img/logo/logo.png" alt="" className="footer-logo" />
                      </div>
                <p>© 2024. RKP & ASSOCIATES. ALL RIGHTS RESERVED.</p>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Footer;