import React, {useEffect} from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { BrowserRouter, Route, Link, Routes, Navigate, Switch, HashRouter} from 'react-router-dom';

function Ritwik() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
     <div><Header /> </div> 

        <div style={{backgroundColor:'#fff', paddingTop:70}}>
        <>
  {/*===== TEAM STARTS =======*/}
    {/*===== WELCOME STARTS =======*/}
    <div
    className="welcome-inner-section-area"
    style={{
      // backgroundImage: "url(assets/img/bacground/inner-bg.png)",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      paddingTop:50,
      background: '#F2F7FB'
    }}
  >
   
    <div className="container">

    <div className="row">
        <div className="col-lg-3 m-auto">
            <img src="assets/img/team/rit.png" alt="" />
            <h3 className="headd">Ritwik Sahay</h3>
            <p className="para" style={{textAlign:'center', marginBottom:10}}> Partner</p>
            <Link onClick={() => window.open('http://linkedin.com/in/ritwik-sahay-22b18715', '_blank')}  ><img src="assets/img/linkedin.png" className="linked linkedd" /></Link> 
        </div>
        <div className="col-lg-9 m-auto">
       
        <div className="team2-parent-boxarea">
         <div className="team2-boxarea" style={{paddingLeft:40}}>
         <p style={{marginTop:15}} className="para">Ritwik advises investors in their M&A deals. He has also been assisting the start-ups in their fund raise exercise.

</p>
         <p style={{marginTop:15}} className="para">He also counsels on mode of structuring of foreign investment, issues surrounding investment structuring, employee stock option plans, and commercial transactions. He has expertise in secretarial, industrial, and regulatory laws which come handy while undertaking due diligence exercises. He has been involved in several due diligence assignments conducted on companies engaged in different industrial sector including IT, Manufacturing, Hotel and Hospitality, Logistics, Healthcare, Gaming etc.

</p>
         </div>
        
       </div>
            </div>

      </div>


    </div>
  </div>

  
  {/*===== TEAM ENDS =======*/}
</>



       
         
        </div>

        <div><Footer /> </div>
    </>
  );
}

export default Ritwik;
