import React, {useEffect} from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { BrowserRouter, Route, Link, Routes, Navigate, Switch, HashRouter} from 'react-router-dom';

function Industry() {
    
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
     <div><Header /> </div> 

        <div style={{backgroundColor:'#F2F7FB', paddingTop:70}} className="inner-pages">
        <>

  <div
    className="welcome-inner-section-area"
    style={{
    
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      padding:50,
      backgroundColor:'#023e8a'
    }}
  >
    
    <div className="container">
      <div className="row">
        <div className="col-lg-8 m-auto">
          <div className="welcome-inner-header text-center">
            <h1>Industry Exposure</h1>
          
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="service-inner-section-area sp1 expo">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="service2-author-box">
            {/* <div className="tax-icon-img">
              <img src="assets/img/icons/investment.png" alt="" className="resize" />
            </div> */}
            <div className="tax-content">
             <h5>Investment Funds</h5>
              <p>Firm has an enriching experience in advising to
the Investment Funds vis-à-vis their investment
strategy and structuring.</p>
<p>Firm assists these funds in conducting legal due
diligence on the investee companies and in
negotiating and drafting the investment
agreements including shareholders agreements
and options agreements.

</p>
            </div>
         
          </div>
        </div>

        <div className="col-lg-12 col-md-12">
          <div className="service2-author-box">
            {/* <div className="tax-icon-img">
              <img src="assets/img/icons/information-technology.png" alt="" />
            </div> */}
            <div className="tax-content">
             <h5>IT, E-Commerce & Outsourcing</h5>
              <p>Firm has considerable experience in
advising companies in information
technology and outsourcing sectors on
various legal issues involving privacy
and data protection.
Firm has advised and assisted several
companies in drafting, negotiating and
finalizing IT related commercial and
licensing contracts.</p>
            </div>
          
          </div>
        </div>
       

        <div className="col-lg-12 col-md-12">
          <div className="service2-author-box">
            {/* <div className="tax-icon-img">
              <img src="assets/img/icons/healthcare.png" alt="" />
            </div> */}
            <div className="tax-content">
             <h5>Healthcare</h5>
              <p>Firm advises domestic and international clients
in the area of pharma and healthcare. Firm has a
dedicated team with in- depth knowledge and
expertise of the legal and regulatory compliances
vis-à-vis pharma and healthcare sector.
Firm has advised and assisted its clients in
preparing and negotiating agreements related to
manufacturing, marketing, importing, exporting
of medicines and related products.
Firm has also assisted its clients in obtaining
regulatory licenses and approvals for
manufacturing and marketing of drugs and
cosmetics in India.
</p>
            </div>
          
           
          </div>
        </div>

        <div className="col-lg-12 col-md-12">
          <div className="service2-author-box">
            {/* <div className="tax-icon-img">
              <img src="assets/img/icons/insurance.png" alt="" />
            </div> */}
            <div className="tax-content">
             <h5>Insurance & Finance
             </h5>
              <p>Firm advises several insurance companies on their litigation matters before all courts
including the Supreme Court of India and National Consumer Forum.
Firm also advises the public and private insurance companies in drafting the insurance
agreements in tune with the changes in the insurance laws.
Firm also assists its clients in setting up NBFCs in India including obtaining NBFC
license from Indian regulatory authority.</p>
            </div>
          
         
          </div>
        </div>
       
        <div className="col-lg-12 col-md-12">
          <div className="service2-author-box">
            {/* <div className="tax-icon-img">
              <img src="assets/img/icons/conveyor.png" alt="" />
            </div> */}
            <div className="tax-content">
             <h5>FMCG
             </h5>
              <p>Firm has been providing end-to-end services to manufacturers and
traders in procuring regulatory approvals, protecting FMCG
brands, and drafting commercial agreements.</p>
            </div>
          
         
          </div>
        </div>

        <div className="col-lg-12 col-md-12">
          <div className="service2-author-box">
            {/* <div className="tax-icon-img">
              <img src="assets/img/icons/public-service.png" alt="" />
            </div> */}
            <div className="tax-content">
             <h5>Public Sector Undertakings
             </h5>
              <p>Firm has been considerable advisor in assisting with the entire
              lifecycle of public sector undertakings.</p>
            </div>
          
         
          </div>
        </div>

      </div>
    </div>
  </div>

</>

         
        </div>

        <div><Footer /> </div>
    </>
  );
}

export default Industry;
