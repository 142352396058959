import React, {useState, useEffect, } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { BrowserRouter, Route, Link, Routes, Navigate, Switch, HashRouter} from 'react-router-dom';



function Expertise() {
   
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
     <div><Header /> </div> 
   

        <div style={{backgroundColor:'#F2F7FB', paddingTop:70}} className="inner-pages">
        <>
      
  <div
    className="welcome-inner-section-area"
    style={{
    
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      padding:50,
      backgroundColor:'#023e8a'
    }}
  >
    
    <div className="container">
      <div className="row">
        <div className="col-lg-4 m-auto">
          <div className="welcome-inner-header text-center">
            <h1>Expertise</h1>
          
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="service-inner-section-area sp1">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-6">
          <div className="service2-author-box">
            {/* <div className="tax-icon-img">
              <img src="assets/img/Mergers & Acquisitions.svg" alt="" className="resize" />
            </div> */}
            <div className="tax-content">
             <h5>Mergers & Acquisitions</h5>
              <p>RKP has a dedicated team of corporate lawyers who
regularly supports the investors and debt-ventures in closing
their deals with a business-oriented approach. Firm also
advises start-ups in achieving their funding requirement.
Being associated closely with these start-ups, we have
acquired an insight into their functioning, which enable us to
understand their unique needs.
</p>
            </div>
          
            <div className="arrow-service">
              <span>
                <a>
                  <img src="assets/img/icons/arrow_right.svg" alt="" />
                </a>
              </span>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6">
          <div className="service2-author-box">
            {/* <div className="tax-icon-img">
              <img src="assets/img/Media-Entertainment.svg" alt="" />
            </div> */}
            <div className="tax-content">
             <h5>Media, Sports & Entertainment</h5>
              <p>Firm provides consultancy to several media houses, film stars,
sports persons, and athletes for their IP rights protection. Firm has
assisted these clients in negotiating and drafting of several media &
entertainment related agreement including production agreement,
Brand Endorsement Agreements, IP Rights Agreement,
Sponsorship Agreements etc.</p>
            </div>
          
            <div className="arrow-service">
              <span>
              <a>
                  <img src="assets/img/icons/arrow_right.svg" alt="" />
                </a>
              </span>
            </div>
          </div>
        </div>
       

        <div className="col-lg-6 col-md-6">
          <div className="service2-author-box">
            {/* <div className="tax-icon-img">
              <img src="assets/img/Corporate-Law.svg" alt="" />
            </div> */}
            <div className="tax-content">
             <h5>Corporate Law Advisory</h5>
              <p>RKP provides corporate law and regulatory advisory on a wide
variety of cross- border transactions and financing structures, legal
due diligence, foreign direct investments, corporate restructures,
corporate franchising. Firm has an enriching experience and
expertise in conducting legal due diligence on entities across all
industrial sectors IT, ITES, online gaming, food & beverages,
healthcare, hospitality, logistics, and consumer products.
</p>
            </div>
          
            <div className="arrow-service">
              <span>
              <a>
                  <img src="assets/img/icons/arrow_right.svg" alt="" />
                </a>
              </span>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6">
          <div className="service2-author-box">
            {/* <div className="tax-icon-img">
              <img src="assets/img/intellectual-Property.svg" alt="" />
            </div> */}
            <div className="tax-content">
             <h5>Labour & Employment
             </h5>
              <p>Firm acknowledges the importance of labour force behind the
success of an organization and recognizes the value of expert advice
on Indian employment laws.
Our team conducts employment and labour audit for its clients on
regular basis. We also provide advice to senior management on
variety of labour and employment issues including employee
contracts, non-compete, confidentiality, non-solicitation, employee
social benefits.</p>
            </div>
          
            <div className="arrow-service">
              <span>
              <a>
                  <img src="assets/img/icons/arrow_right.svg" alt="" />
                </a>
              </span>
            </div>
          </div>
        </div>


        <div className="col-lg-6 col-md-6">
          <div className="service2-author-box">
            {/* <div className="tax-icon-img">
              <img src="assets/img/intellectual-Property.svg" alt="" />
            </div> */}
            <div className="tax-content">
             <h5>Capital Market
             </h5>
              <p>Our new capital market practice has received a warm
response from clients. We offer full advisory in securities
laws, and assist companies in their IPOs, private placements,
and qualified institutions placement.
Firm has been representing issuers, merchant bankers,
promoters and institutional selling shareholders from time to
time.
</p>
            </div>
          
            <div className="arrow-service">
              <span>
              <a>
                  <img src="assets/img/icons/arrow_right.svg" alt="" />
                </a>
              </span>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6">
          <div className="service2-author-box">
            {/* <div className="tax-icon-img">
              <img src="assets/img/intellectual-Property.svg" alt="" />
            </div> */}
            <div className="tax-content">
             <h5>Intellectual Property Rights
             </h5>
              <p>Firm's trademark and patent attorneys provide end-to-end
support in protection of client’s Trademarks, Patents,
Designs & Copyrights. We also support our clients in
creation of trademarks and brand-building.
We also offer our expertise in providing services qua IP
Investigation, search & seizure of infringing products and
taking aggressive measures against the infringers.
RKP also has a strong presence in IP litigations at all levels
of tribunals and courts.</p>
            </div>
          
            <div className="arrow-service">
              <span>
              <a>
                  <img src="assets/img/icons/arrow_right.svg" alt="" />
                </a>
              </span>
            </div>
          </div>
        </div>

       
     
        <div className="col-lg-6 col-md-6">
          <div className="service2-author-box">
            {/* <div className="tax-icon-img">
              <img src="assets/img/intellectual-Property.svg" alt="" />
            </div> */}
            <div className="tax-content">
             <h5>White Collar Crime
             </h5>
              <p>Firm handles complex criminal matters and white-collar
crimes. We advise and represent clients on regular basis
before investigating agencies.</p>
            </div>
          
            <div className="arrow-service">
              <span>
              <a>
                  <img src="assets/img/icons/arrow_right.svg" alt="" />
                </a>
              </span>
            </div>
          </div>
        </div>
       
        <div className="col-lg-6 col-md-6">
          <div className="service2-author-box">
            {/* <div className="tax-icon-img">
              <img src="assets/img/intellectual-Property.svg" alt="" />
            </div> */}
            <div className="tax-content">
             <h5>Dispute Resolution

             </h5>
              <p>Firm has expertise of handling all sorts of commercial dispute
resolution, either in an arbitration or mediation proceeding,
or before the conventional court proceedings.
</p>
            </div>
          
            <div className="arrow-service">
              <span>
              <a>
                  <img src="assets/img/icons/arrow_right.svg" alt="" />
                </a>
              </span>
            </div>
          </div>
        </div>
       

        <div className="col-lg-6 col-md-6">
          <div className="service2-author-box">
            {/* <div className="tax-icon-img">
              <img src="assets/img/intellectual-Property.svg" alt="" />
            </div> */}
            <div className="tax-content">
             <h5>Insolvency & Bankruptcy Proceedings
             </h5>
              <p>Firm handles disputes arising out of Insolvency Law. The
firm’s litigators represent Creditors, Insolvency Professionals
and Corporate Debtors before the NCLT and NCLAT on
regular basis. Firm is actively involved in the preparation of
resolution plans.
</p>
            </div>
          
            <div className="arrow-service">
              <span>
              <a>
                  <img src="assets/img/icons/arrow_right.svg" alt="" />
                </a>
              </span>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6">
          <div className="service2-author-box">
            {/* <div className="tax-icon-img">
              <img src="assets/img/intellectual-Property.svg" alt="" />
            </div> */}
            <div className="tax-content">
             <h5>Insurance
             </h5>
              <p>We offer a full complement of legal services in the insurance
industry. We act on behalf of a number of major insurers in
respect of both short- and long-term insurance.
Our services include advisory on regulatory aspects and
compliance with the applicable legislation.
</p>
            </div>
          
            <div className="arrow-service">
              <span>
              <a>
                  <img src="assets/img/icons/arrow_right.svg" alt="" />
                </a>
              </span>
            </div>
          </div>
        </div>
       

       
     

       
        <div className="col-lg-6 col-md-6">
          <div className="service2-author-box">
            {/* <div className="tax-icon-img">
              <img src="assets/img/intellectual-Property.svg" alt="" />
            </div> */}
            <div className="tax-content">
             <h5>Real Estate

             </h5>
              <p>Firm advises in matters and documentations concerned with
              lease, secured lending, mortgages, and charges.
</p>
            </div>
          
            <div className="arrow-service">
              <span>
              <a>
                  <img src="assets/img/icons/arrow_right.svg" alt="" />
                </a>
              </span>
            </div>
          </div>
        </div>
       
        <div className="col-lg-6 col-md-6">
          <div className="service2-author-box">
            {/* <div className="tax-icon-img">
              <img src="assets/img/intellectual-Property.svg" alt="" />
            </div> */}
            <div className="tax-content">
             <h5>Regulatory
             </h5>
              <p>Firm is actively involved in making representations before
Government regulators and authorities on behalf of its
clients.</p>
            </div>
          
            <div className="arrow-service">
              <span>
              <a>
                  <img src="assets/img/icons/arrow_right.svg" alt="" />
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</>

         
        </div>

        <div><Footer /> </div>
    </>
  );
}

export default Expertise;
