import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { BrowserRouter, Route, Link, Routes, Navigate, Switch, HashRouter} from 'react-router-dom';

function Gautam() {
  return (
    <>
     <div><Header /> </div> 

        <div style={{backgroundColor:'#fff', paddingTop:70}}>
        <>
  {/*===== TEAM STARTS =======*/}
    {/*===== WELCOME STARTS =======*/}
    <div
    className="welcome-inner-section-area"
    style={{
      // backgroundImage: "url(assets/img/bacground/inner-bg.png)",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      paddingTop:50,
      background: '#F2F7FB'
    }}
  >
   
    <div className="container">

    <div className="row">
        <div className="col-lg-3 m-auto">
            <img src="assets/img/team/gautam.png" alt="" />
            
        </div>
        <div className="col-lg-9 m-auto">
        <h3 className="headd">Gautam Sabharwal</h3>
            <p className="para"> Partner</p>

            </div>

      </div>


    </div>
  </div>

  <div className="team2-section-area team-inner inn">
    <div className="container-fluid">
    <div className="row" style={{marginBottom:30}}>
     
  
     <div className="col-lg-12 col-md-12">
       <div className="team2-parent-boxarea">
         <div className="team2-boxarea">
         <p style={{marginTop:15}} className="para">Gautam has an experience in dealing with various bodies of Government of India, its policies and procedures and ensuring Legal Compliance.


         </p>
         <p style={{marginTop:15}} className="para">He has worked with TATA Group from 1984 to 1989 and thereafter with Khatau Group from 1989 to 1994. During this period, he was working on expansion projects of TATA Steel, acquisition & merger of Merck Sharpe & Dhome into TATA Group, disinvestment of Siemens back to Siemens AG, provided legal consultancy to Australian Wheat Board India Ltd., Victorinox SA, Switzerland, Punj Lloyd Ltd. and presently actively involved with some companies in Oil & Natural Gas Sector

He is also a trustee in Pran Sabharwal Foundation and the Chairman of the Executive Committee in IPCS</p>
         </div>
        
       </div>
     </div>
</div>


    </div>
  </div>
  {/*===== TEAM ENDS =======*/}
</>



       
         
        </div>

        <div><Footer /> </div>
    </>
  );
}

export default Gautam;
