import React, {useEffect} from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';

function News() {
    
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
     <div><Header /> </div> 

     <div style={{backgroundColor:'#F2F7FB', paddingTop:70}} className="inner-pages">
        <>

  <div
    className="welcome-inner-section-area"
    style={{
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      padding:50,
      backgroundColor:'#023e8a'
    }}
  >
    
    <div className="container">
      <div className="row">
        <div className="col-lg-4 m-auto">
          <div className="welcome-inner-header text-center">
            <h1>Newsroom</h1>
          
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="service-inner-section-area sp1 news">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-4">
          <div className="service2-author-box">
        <Link onClick={() => window.open('https://www.linkedin.com/feed/update/urn:li:activity:7202885389760532480', '_blank')}  ><img src="assets/img/imm/news1.jpeg" alt="" /></Link>
            <div className="tax-content">
            <Link onClick={() => window.open('https://www.linkedin.com/feed/update/urn:li:activity:7202885389760532480', '_blank')}  ><h5>Navigating Compliances under UCPMP 2024</h5></Link>
            <Link onClick={() => window.open('https://www.linkedin.com/feed/update/urn:li:activity:7202885389760532480', '_blank')}  >Read More &nbsp;
              <i class="fa-thin fa-arrow-right"></i>
                        </Link>
            </div>
          
          
          </div>
        </div>

        <div className="col-lg-4 col-md-4">
        <div className="service2-author-box">
        <Link onClick={() => window.open('https://www.linkedin.com/feed/update/urn:li:activity:7195036998527569920', '_blank')}  ><img src="assets/img/imm/news2.jpeg" alt="" /></Link>
            <div className="tax-content">
            <Link onClick={() => window.open('https://www.linkedin.com/feed/update/urn:li:activity:7195036998527569920', '_blank')}  ><h5>Understanding RBI's Draft Framework for Project Loans</h5></Link>
            <Link onClick={() => window.open('https://www.linkedin.com/feed/update/urn:li:activity:7195036998527569920', '_blank')}  >Read More &nbsp;
              <i class="fa-thin fa-arrow-right"></i>
                        </Link>
            </div>
          
          
          </div>
        </div>
       
        <div className="col-lg-4 col-md-4">
        <div className="service2-author-box">
        <Link onClick={() => window.open('https://www.linkedin.com/feed/update/urn:li:activity:7192541152208662528', '_blank')}  ><img src="assets/img/imm/news3.jpeg" alt="" /></Link>
            <div className="tax-content">
            <Link onClick={() => window.open('https://www.linkedin.com/feed/update/urn:li:activity:7192541152208662528', '_blank')}  ><h5>IFSCA Circular Enables FPIs at GIFT IFSC to Issue P Notes</h5></Link>
            <Link onClick={() => window.open('https://www.linkedin.com/feed/update/urn:li:activity:7192541152208662528', '_blank')}  >Read More &nbsp;
              <i class="fa-thin fa-arrow-right"></i>
                        </Link>
            </div>
          
          
          </div>
        </div>


        <div className="col-lg-4 col-md-4">
        <div className="service2-author-box">
        <Link onClick={() => window.open('https://www.linkedin.com/feed/update/urn:li:activity:7185176623669755904', '_blank')}  ><img src="assets/img/imm/news4.jpeg" alt="" /></Link>
            <div className="tax-content">
            <Link onClick={() => window.open('https://www.linkedin.com/feed/update/urn:li:activity:7185176623669755904', '_blank')}  ><h5>Navigating Compliances Under India's DPDPA</h5></Link>
            <Link onClick={() => window.open('https://www.linkedin.com/feed/update/urn:li:activity:7185176623669755904', '_blank')}  >Read More &nbsp;
              <i class="fa-thin fa-arrow-right"></i>
                        </Link>
            </div>
          
          
          </div>
        </div>
     

      </div>
    </div>
  </div>

</>

         
        </div>

        <div><Footer /> </div>
    </>
  );
}

export default News;
