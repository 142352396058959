import React,{useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Link, Routes, Navigate, Switch, HashRouter, useNavigate} from 'react-router-dom';
import Home from "./Components/Home";
import About from "./Components/About";
import Contact from './Components/Contact';
import Team from './Components/Team';
import Ranjan from './Components/Ranjan';
import Gautam from './Components/Gautam';
import Kuldeep from './Components/Kuldeep';
import Ritwik from './Components/Ritwik';
import Sandeep from './Components/Sandeep';
import Expertise from './Components/Expertise';
import News from './Components/News';
import Industry from './Components/Industry';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <BrowserRouter>
  
  <Routes>
    
      <Route path="/" element={<Home />} /> 
      <Route path="/about" element={<About />} /> 
      <Route path="/contact" element={<Contact />} /> 
      <Route path="/team" element={<Team />} /> 
      <Route path="/ranjan" element={<Ranjan />} /> 
      <Route path="/gautam" element={<Gautam />} /> 
      <Route path="/kuldeep" element={<Kuldeep />} /> 
      <Route path="/ritwik" element={<Ritwik />} /> 
      <Route path="/sandeep" element={<Sandeep />} /> 
      <Route path="/expertise" element={<Expertise />} /> 
      <Route path="/news" element={<News />} /> 
      <Route path="/industry-exposure" element={<Industry />} /> 
      
      
  </Routes>
</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
