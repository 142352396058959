import React, {useEffect} from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { BrowserRouter, Route, Link, Routes, Navigate, Switch, HashRouter} from 'react-router-dom';

function Ranjan() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
     <div><Header /> </div> 

        <div style={{backgroundColor:'#fff', paddingTop:70}}>
        <>
  {/*===== TEAM STARTS =======*/}
    {/*===== WELCOME STARTS =======*/}
    <div
    className="welcome-inner-section-area"
    style={{
      // backgroundImage: "url(assets/img/bacground/inner-bg.png)",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      paddingTop:50,
      background: '#F2F7FB'
    }}
  >
   
    <div className="container">
      <div className="row">
        <div className="col-lg-3 m-auto">
            <img src="assets/img/team/ran.png" alt="" />
            <h3 className="headd">Ranjan Kumar Pandey</h3>
            <p className="para" style={{textAlign:'center', marginBottom:10}}>Founding Partner</p>
            <Link onClick={() => window.open('http://linkedin.com/in/ranjan-k-pandey-a2104020', '_blank')}  ><img src="assets/img/linkedin.png" className="linked linkedd" /></Link> 
        </div>
        <div className="col-lg-9 m-auto">
       
            <div className="team2-parent-boxarea">
         <div className="team2-boxarea" style={{paddingLeft:40}}>
         <p style={{marginTop:15}} className="para">
         Ranjan is the founder of the firm and has been instrumental in the growth of the firm over a
period of time. He is an Advocate-on-Record in Supreme Court of India and a life- time member
of Supreme Court Bar Association. He is having an enriching experience of over 25 years in the
legal practice.
         </p>
         <p style={{marginTop:15}} className="para">Ranjan has been looking after the over-all practice of the firm with a greater emphasis on high
stake corporate matters including merger & acquisition and private equity. He has also been
involved in several high level corporate & commercial litigations and arbitrations including whitecollar criminal proceedings before the Supreme Court and other courts in India.
Ranjan is on the panel of several companies, public sector units and Universities as a legal expert.
Apart from being empaneled with several entities, he also holds prestigious position on the
advisory boards of several international corporate bodies including A&S Pharma, Anjaneyap
Ranjan Kumar Pandey Inc., and Ceratec Dimensions Inc.</p>
         </div>
        
       </div>
            </div>

      </div>
    </div>
  </div>

  
  {/*===== TEAM ENDS =======*/}
</>



       
         
        </div>

        <div><Footer /> </div>
    </>
  );
}

export default Ranjan;
