import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';

function Header() {

  const [collapsed, setCollapsed] = useState(false);

  const handleToggleSidebar = () => {
    setCollapsed(!collapsed);
  };


  return (
    <header className="header d-lg-block">
        <div className="header-area header homepage7 header-sticky" id="header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="header-top-area">
                
                  <div className="header-elements">
                    <div className="site-logo">
                      <Link to="/"><img src="assets/img/logo/logo.png" alt="" className='logo' /></Link>
                    </div>
                    <div style={{display:'flex', alignItems:'center'}}>
                    <div className="main-menu-ex homepage6 d-none ">
                      <ul>
                        <li><Link to="/">Home</Link></li>
                        {/* <li><Link to="/about">About Us</Link></li> */}
                        <li><Link to="/expertise">Expertise</Link></li>
                        <li><Link to="/industry-exposure">Industry Exposure</Link> </li>
                        <li><Link to="/team">Team</Link></li>
                        {/* <li><Link to="/contact">Contact Us</Link></li> */}
                        <li><Link to="/news">Newsroom</Link></li>
                      </ul>
                    </div>
                    <div className="contact-3 d-lg-block">
                    <button onClick={handleToggleSidebar} style={{background:'transparent', border:'none', marginLeft:'20px'}}>  <i className="fa-solid fa-bars" /></button>
                    </div>
                   </div>
                    {/* <aside className="slide-bar slide-bar6">
                      <div className="close-mobile-menu">
                        <a className="tx-close" />
                      </div>
                      <div className="sidebar-info sidebar-info6">
                       
                      
                        <ul className="sidebar-menu list-unstyled">
                        <li><Link to="/">Home</Link></li>
                       
                        <li><Link to="/expertise">Expertise</Link></li>
                        <li><Link to="/industry-exposure">Industry Exposure</Link> </li>
                        <li><Link to="/team">Team</Link></li>
                      
                       
                        <li><Link to="/news">Newsroom</Link></li>
                        </ul>
                  
                      </div>
                  
                    </aside> */}



                  
                    <Sidebar collapsed={collapsed} >
     
                    <ul className="sidebar-menu list-unstyled">
                        <li><Link to="/">Home</Link></li>
                       
                        <li><Link to="/expertise">Expertise</Link></li>
                        <li><Link to="/industry-exposure">Industry Exposure</Link> </li>
                        <li><Link to="/team">Team</Link></li>
                      
                       
                        <li><Link to="/news">Newsroom</Link></li>
                        </ul>
   
    </Sidebar>
                    <div className="body-overlay" />
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
  );
}

export default Header;
